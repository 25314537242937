import GATSBY_COMPILED_MDX from "/vercel/path0/content/posts/2023-12/2023-12-20-tech-invasion-the-latest-innovations-for-anglers-and-boaters-bdoutdoors-bdoutdoors-on-the-wire.md";
import * as React from "react";
import {graphql} from "gatsby";
import Layout from "../../components/Layout";
import Seo from "../../components/Seo";
import {useTina} from "tinacms/dist/react";
import {TinaMarkdown} from "tinacms/dist/rich-text";
import {GatsbyImage, getImage} from "gatsby-plugin-image";
import BlogPostHeroImage from "../../components/blog/BlogPostHeroImage";
import PropTypes from "prop-types";
const BlogPostPage = ({data, children}) => {
  const {blog} = data;
  const _input = {
    post: {
      title: blog.content.meta.title,
      body: null,
      _sys: {
        filename: `${blog.slug}.md`
      },
      id: blog.content.id
    }
  };
  const {data: tinaData} = useTina({
    query: `query BlogPostQuery($relativePath: String!) {
        post(relativePath: $relativePath) {
          title
          id
          body
          hero
          _sys 
          {
            filename
          }
          
        }
      }`,
    variables: {
      relativePath: `${blog.slug}.md`
    },
    data: _input
  });
  return React.createElement(Layout, {
    title: tinaData.post.title,
    canonical: "https://yachting.ai/" + blog.slug,
    breadcrumbs: [{
      url: "/",
      name: "Home"
    }, {
      url: "/blog",
      name: "Blog"
    }, {
      url: "/blog/" + blog.slug,
      name: blog.title
    }],
    breadcrumbsJustMeta: true,
    className: "main-blog"
  }, React.createElement(Seo, {
    title: `${blog?.content?.meta?.title} | Blog | Yachting AI`,
    description: blog.excerpt,
    canonical: `https://yachting.ai/${blog.slug}`,
    image: `https://yachting.ai/hero-image.png`
  }), React.createElement("div", {
    className: "px-6 pt-28 pb-16 max-container blog"
  }, React.createElement(BlogPostHeroImage, {
    blog: blog.content,
    className: "w-full aspect-video mb-4 object-cover rounded-md"
  }), tinaData?.post?.body ? React.createElement(React.Fragment, null, tinaData.post.hero && React.createElement("img", {
    src: tinaData.post.hero,
    alt: tinaData.title
  }), React.createElement(TinaMarkdown, {
    content: tinaData.post.body
  })) : React.createElement(React.Fragment, null, React.createElement(GatsbyImage, {
    alt: blog.content.meta.title,
    image: getImage(blog.content.hero)
  }), children)));
};
BlogPostPage.propTypes = {
  data: PropTypes.object,
  children: PropTypes.node
};
export const query = graphql`
  query BlogPosts($id: String) {
    blog: file(childMdx: { id: { eq: $id } }) {
      slug: name
      content: childMdx {
        id
        hero: heroImage {
          childImageSharp {
            gatsbyImageData
          }
        }
        meta: frontmatter {
          title
          herourl
        }
        excerpt
      }
    }
  }
`;
BlogPostPage
export default function GatsbyMDXWrapper(props) {
  return React.createElement(BlogPostPage, props, React.createElement(GATSBY_COMPILED_MDX, props));
}
